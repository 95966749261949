import {get, post, download} from "./index.js";

/**
 * 抵扣统计信息
 * @param {*} data
 */
export const getDeductionStatistics = () => {
    return get("/passenger/deduction/getDeductionStatistics", {}).then(res => res);
}

/**
 * 抵扣列表（分页）
 * @param {*} data
 */
export const getDeductionListByPage = (data) => {
    return post("/passenger/deduction/getDeductionListByPage", data).then(res => res);
}
/**
 * 勾选全部
 * @param {*} data
 */
export const choiceAllDeductionInfo = (data) => {
    return post("/passenger/deduction/choiceAllDeductionInfo", data).then(res => res);
}
/**
 * 确认抵扣检查
 * @param {*} data
 */
export const confirmDeductionCheck = (data) => {
    return post("/passenger/deduction/confirmDeductionCheck", data).then(res => res);
}
/**
 * 确认抵扣
 * @param {*} data
 */
export const confirmDeduction = (data) => {
    return post("/passenger/deduction/confirmDeduction", data).then(res => res);
}
/**
 * 导出抵扣Excel
 * @param {*} data
 */
export const exportDeductionExcel = (data) => {
    return download('/passenger/deduction/download', data).then(res => res);
}
/**
 * 旅客抵扣统计列表（分页）
 * @param {*} data
 */
export const getDeductionStatisticsByPage = (data) => {
    return post("/passenger/statistics/getDeductionStatisticsByPage", data).then(res => res);
}
/**
 * 抵扣统计详情列表（分页）
 * @param {*} data
 */
export const getStatisticsDetailsListByPage = (data) => {
    return post("/passenger/statistics/getStatisticsDetailsListByPage", data).then(res => res);
}
/**
 * 导出抵扣统计Excel
 * @param {*} data
 */
export const exportDeductionStatisticsExcel = (data) => {
    return download('/passenger/statistics/download', data).then(res => res);
}

/**
 * 进项发票勾选抵扣（分页
 */
export const pagingDeductInvoiceUnauthenticatedList = (param) => {
    return get("/deduct/income-invoice/page/unauthenticated", param).then(res => res);
}

/**
 * 进项发票勾选抵扣统计
 * @param data
 * @returns {Promise<unknown>}
 */
export const getIncomeInvoicesStatistics = data => {
    return post("/deduct/income-invoice/summary/unauthenticated", data).then(res => res);
}

/**
 * 获取设备在线状态
 * @param param
 * @returns {Promise<unknown>}
 */
export const reqState = data => {
    return post("/deduct/income-request/reqState", data).then(res => res);
}

/**
 * 获取授权信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const reqToken = data => {
    return post("/deduct/income-request/reqToken", data).then(res => res);
}

/**
 * 提交发票勾选
 * @param data
 * @returns {Promise<unknown>}
 */
export const submitInvoicesToCheck = data => {
    return post("/deduct/income-request/submitInvoicesToChecked", data).then(res => res);
}

/**
 * 认证清单查询
 */
export const pagingDeductInvoiceAuthenticationList = (param) => {
    return get("/deduct/income-invoice/page/authentication", param).then(res => res);
}

export const summaryDeductInvoiceAuthentication = (param) => {
    return get("/deduct/income-invoice/summary/authentication", param).then(res => res);
}

/**
 * 发票批量导出
 * @param data
 * @returns {Promise<unknown>}
 */
export const invoiceExportBatch = data => {
    return download("/deduct/income-invoice/exportInvoiceBatch", data).then(res => res);
}

/**
 * 异常发票列表
 */
export const pagingDeductInvoiceExceptionList = (param) => {
    return get("/deduct/income-invoice/page/exception", param).then(res => res);
}

export const summaryDeductInvoiceException = (param) => {
    return get("/deduct/income-invoice/summary/exception", param).then(res => res);
}

/**
 * 异常发票修改备注
 * @param data
 * @returns {Promise<unknown>}
 */
export const updateExceptionInvoiceRemark = (data) => {
    return post("/deduct/income-invoice/update/remark", data).then(res => res);
}

/**
 * 异常发票导出
 * @param data
 * @returns {Promise<unknown>}
 */
export const exportExceptionInvoice = data => {
    return download("/deduct/income-invoice/exportExceptionInvoice", data).then(res => res);
}


/**
 * 当前所属期申请统计
 * @param data
 */
export const deductApplyStatistic = data => {
    return post("/deduct/income-request/deductApplyStatistic", data).then(res => res);
}

/**
 * 获取统计标识
 * @param param
 * @returns {Promise<unknown>}
 */
export const getStatisticFlag = param => {
    return get("/deduct/income-request/statisticFlag/" + param).then(res => res);
}

/**
 * 数据同步接口
 * @param data
 * @returns {Promise<unknown>}
 */
export const syncData = data => {
    return post("/deduct/income-request/syncData", data).then(res => res);
}

/**
 * 当前所属期申请确认
 * @param data
 * @returns {Promise<unknown>}
 */
export const applyConfirm = data => {
    return post("/deduct/income-request/applyConfirm", data).then(res => res);
}

/**
 * 单票查询接口
 * @param data
 * @returns {Promise<unknown>}
 */
export const deductGetInvoice = data => {
    return post("/deduct/income-request/deductGetInvoice", data).then(res => res);
}

/**
 * 获取统计信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const getDeductIncomeStatistics = data => {
    return post("/deduct/income-statistics/getDeductIncomeStatistics", data).then(res => res);
}

/**
 * 在缓存中获取进项流程状态
 * @param param
 * @returns {Promise<unknown>}
 */
export const getDeductIncomeProcessStatus = param => {
    return get("/deduct/income-request/getDeductIncomeProcessStatus/" + param).then(res => res);
}

/**
 * 勾选发票导出
 * @param data
 * @returns {Promise<unknown>}
 */
export const downloadDeductExcel = data => {
    return download("/deduct/income-invoice/deductExport", data).then(res => res);
}

/**
 * 清除进项状态缓存设置
 * @param param
 * @returns {Promise<unknown>}
 */
export const clearCacheState = param => {
    return get("/deduct/income-request/clearCacheState/" + param).then(res => res);
}

// 查询组织下所有数电账户
export const getDzsjUser = (data) => {
    return post('/rpa/page',data).then(res => res);
}

export const getSmsCode = (data) => {
    return post('/deduct/income-request/auth/get-sms-code', data).then(res => res);
}

// export const reqToken = (data) => {
//   return post('/deduct/income-request/reqToken', data).then((res) => res);
// };

