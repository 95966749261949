<template>
  <el-dialog
    width="480px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
  >
    <div style="margin-bottom: 20px; font-weight: 700">{{ titleText }}</div>
    <el-form
      v-if="sendSuccess === '1'"
      :model="equipment"
      :label-position="labelPosition"
      label-width="120px"
    >
      <el-form-item label="组织名称：" style="margin-bottom: 20px">
        <el-input v-model="equipment.name" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="组织税号：" style="margin-bottom: 20px">
        <el-input v-model="equipment.taxNo" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="税局账号：" style="margin-bottom: 20px">
        <el-select
          v-model="equipment.usernameForRpa"
          @change="handlerChange"
          placeholder="请选择税局账号"
          clearable
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.elecAccount"
            :value="item.elecAccount"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="电子税局密码：" style="margin-bottom: 20px">
        <el-input v-model="equipment.passwordForRpa" placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <div v-if="sendSuccess === '2'">
      <p style="margin-top: 30px; font-weight: 700; font-size: 14px">
        请输入短信验证码
      </p>
      <p style="margin: 20px 0; font-size: 14px">
        验证码已发送至{{ cellPhone }}，请注意查收
      </p>
      <el-input
        style="margin: 20px 0; font-size: 14px"
        maxlength="6"
        v-model="equipment.smsVerifyCode"
        placeholder="请输入短信验证码"
      ></el-input
      ><span
        style="
          font-size: 14px;
          font-weight: 200;
          color: #1890ff;
          margin-left: 12px;
        "
        v-show="!showTime"
        >{{ getTime }}秒</span
      >
      <el-button
        style="margin-left: 12px"
        v-show="showTime"
        @click="sendIng"
        type="primary"
        >重新发送</el-button
      >
    </div>
    <div v-if="sendSuccess === '3'">
      <p style="margin-top: 30px; font-size: 14px">
        组织名称：{{ equipment.name }}
      </p>
      <p style="margin: 20px 0; font-size: 14px">
        组织税号：{{ equipment.taxNo }}
      </p>
      <div style="border-bottom: 1px solid; width: 100%; color: #d7d7d7"></div>
      <p style="margin-top: 30px; font-size: 14px">
        认证方式：{{ authorizeMode ? authorizeMode : "尚未设置" }}
      </p>
      <p style="margin: 20px 0; font-size: 14px">
        当前电子税局账号：{{ equipment.usernameForRpa }}
      </p>
      <p style="margin: 20px 0; font-size: 14px">
        当前登录状态：<span style="color: #03b615">授权成功</span>
      </p>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button v-if="sendSuccess !== '3'" @click="handlerClose"
        >取 消</el-button
      >
      <el-button
        v-if="sendSuccess === '1'"
        type="primary"
        @click="handleConfirm('1')"
        >登录</el-button
      >
      <el-button
        v-if="sendSuccess === '2'"
        type="primary"
        @click="handleConfirm('2')"
        >确认</el-button
      >
      <el-button
        v-if="sendSuccess === '3'"
        type="primary"
        @click="handleConfirm('3')"
        >确认</el-button
      >
      <el-button
        v-if="sendSuccess === '3'"
        type="primary"
        @click="handleConfirm('4')"
        >授权</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import PDialog from "@/components/pdt/PDialog";
import { changeMethods } from "@/util/mixins";

export default {
  name: "IncomeInfoDialog",
  components: { PDialog },
  props: ["visible", "equipment", "userList", "sendSuccess"],
  mixins: { changeMethods },
  data() {
    return {
      dialogVisible: false,
      verificationCodeFrom: {},
      labelPosition: "right",
      titleText: "授权登录",
      cellPhone: "",
      getTime: 60,
      timer: "",
      showTime: true,
      authorizeModeList: [
        { label: "税盘", value: "ZS" },
        { label: "UKey服务器", value: "UK" },
        { label: "软证书", value: "RZS" },
        { label: "SD-R-X", value: "RPA" },
      ],
    };
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible;
    },
    sendSuccess: {
      handler(val) {
        this.titleText = val === "3" ? "登录信息详情" : "授权登录";
        if (val === "2") {
          this.sendIng();
        }
      },
    },
  },
  computed: {
    authorizeMode() {
      let item = this.authorizeModeList.filter(
        (item) => item.value === this.equipment.authorizeMode
      );
      console.log(item);
      if (item.length) {
        return item[0].label;
      }
    },
  },
  methods: {
    handlerChange(data) {
      let i = this.userList.filter((item) => item.elecAccount === data);
      this.equipment.passwordForRpa = i[0].password;
      this.cellPhone =
        this.equipment.usernameForRpa.substring(0, 3) +
        "****" +
        this.equipment.usernameForRpa.substring(7);
    },
    sendIng() {
      let timer = setInterval(() => {
        this.getTime -= 1;
        if (this.getTime <= 0) {
          clearInterval(timer);
          this.showTime = true;
          this.getTime = 60;
        }
      }, 1000);
      this.showTime = false;
    },
    handlerClose() {
      this.dialogVisible = false;
      this.$emit("update:visible", false);
    },
    handleConfirm(type) {
      if (type === "1") {
        this.$emit("getVerificationCode", this.equipment);
      } else if (type === "2") {
        this.$emit("verificationCodeNotarize", this.equipment);
      } else if (type === "4") {
        if (!this.equipment.isReadied) {
          this.toast("当前组织设备未就绪", "error");
          return;
        }
        this.$emit("confirmRpa", false);
        this.handlerClose();
      } else {
        this.dialogVisible = false;
        this.$emit("update:visible", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-header {
  padding: 24px;
  font-size: 14px;
  border-bottom: 1px solid #e9e9e9;

  div {
    margin-bottom: 12px;
  }
}

.info-content {
  display: flex;
  padding: 24px 0;
  font-size: 14px;
  flex-direction: row;

  .content-items {
    flex: auto;
    display: flex;
    padding-left: 6px;
    flex-direction: column;

    div {
      padding-bottom: 16px;
    }

    label {
      width: 80px;
      display: inline-block;
    }
  }

  img {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
  }
}

label {
  color: #666666;
}

span {
  color: #333333;
}
</style>
